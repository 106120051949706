import React, {useMemo} from 'react'
import {observer} from 'mobx-react-lite'
import {useRefinementList} from 'react-instantsearch-hooks-web'
import {RefinementsListItem} from '../ui/refinements-list-item'
import {REFINEMENTS_ID_MAP} from '../../../../../utils/constants'

interface RefinementList {
  attribute: string
  label: string
  lastItem: boolean
  isInDrawer?: boolean
}

type RefinementItem = ReturnType<typeof useRefinementList>['items'][number]

export const GeneralRefinements = observer((props: RefinementList) => {
  const {attribute, label, lastItem, isInDrawer} = props
  const {items, refine} = useRefinementList({attribute})
  
  const handleRefine = (item: RefinementItem) => {
    if (attribute === REFINEMENTS_ID_MAP.PRICE) {
      items
        .filter((item) => item.isRefined)
        .forEach((item) => {
          refine(item.value)
        })

      refine(item.value)

      return
    }

    refine(item.value)
  }

  const sortRefinementsItems = (items: RefinementItem[]) => {
    if (
      [
        REFINEMENTS_ID_MAP.PACK_SIZE,
        REFINEMENTS_ID_MAP.LITRE,
        REFINEMENTS_ID_MAP.PRICE,
        REFINEMENTS_ID_MAP.WEIGHT,
        REFINEMENTS_ID_MAP.MANUFACTURE_SKU,
        REFINEMENTS_ID_MAP.SERVING,
      ].includes(attribute)
    ) {
      return [...items].sort((prev, next) => {
        if (!prev.label || !next.label) return 0

        const prevNum = Number(
          prev.label
            .split('-')[0]
            .match(/[+-]?\d+(\.\d+)?/g)
            ?.join('') ?? Number.NaN
        )

        const nextNum = Number(
          next.label
            .split('-')[0]
            .match(/[+-]?\d+(\.\d+)?/g)
            ?.join('') ?? Number.NaN
        )

        if (Number.isNaN(nextNum) && Number.isNaN(prevNum))
          return prev.label.localeCompare(prev.label)

        if (Number.isNaN(prevNum)) return 1

        if (Number.isNaN(nextNum)) return -1

        return prevNum - nextNum
      })
    }

    return items
  }

  const sortedRefinementItems = useMemo(() => sortRefinementsItems(items), [items])

  return (
    <RefinementsListItem
      checkIcon={true}
      alwaysHighlighted={false}
      isInDrawer={isInDrawer}
      label={label}
      lastItem={lastItem}
      onSelect={handleRefine}
      items={sortedRefinementItems}
      highlightedLabels={sortedRefinementItems
        .filter((item) => item.isRefined)
        .map(({label}) => label)}
    />
  )
})
