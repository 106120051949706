import React, {Fragment} from 'react'
import {observer} from 'mobx-react'
//@ts-ignore
import {useRouteMatch} from 'react-router-dom'
import {useClearRefinements, useInstantSearch} from 'react-instantsearch-hooks-web'
import {BaseButton, Flex, Text} from '../../../vanilla'
import {objectKeys} from '../../../utils/utils'
import {CloseIcon} from '../../icons'
import {useCategoryStore} from '../../../store/hooks/useStore'
import {SEARCH_DEFAULT_REFINEMENTS} from '../../../utils/constants'
import {hideScroll, searchButtonItem} from './styles.css'

interface RefinementsDisplayProps {
  category: {id: string; name: string}
}

export const RefinementsDisplayContainer = ({category}: RefinementsDisplayProps) => {
  const {
    indexUiState: {refinementList = {}},
  } = useInstantSearch()

  const {categoryRefinements} = useCategoryStore()
  const {isExact: isSearchPage} = useRouteMatch('/search') || {isExact: false}
  const {canRefine, refine} = useClearRefinements()
  const refinements = isSearchPage ? SEARCH_DEFAULT_REFINEMENTS : categoryRefinements[category?.id]

  return (
    <Flex className={hideScroll} flexWrap="wrap" align="center" gap="8px">
      {objectKeys(refinementList).map((refinementAttr, idx) => {
        const {label} =
          (refinements || []).find(({attribute}) => attribute === refinementAttr) || {}

        const hasRefinements = refinementList[refinementAttr].length

        return label && hasRefinements ? (
          <Fragment key={`${refinementAttr}-${idx}`}>
            <Text variant="text3" marginLeft={idx !== 0 ? '20px' : '0px'} marginRight="8px">
              {label}
            </Text>
            {refinementList[refinementAttr].map((refinement, i) => (
              <BaseButton
                key={`${refinementAttr}-${refinement}-${i}`}
                display="inline-flex"
                extraClassName={searchButtonItem}
                gap="4px"
                onClick={() => {if (canRefine) refine()}}
                cursor="pointer"
                alignItems="center"
                variant="unstyled"
              >
                <Text variant="unstyled" fontSize="inherit" fontWeight="inherit" color="accent3">
                  {refinement}
                </Text>
                <CloseIcon width="12px" color="accent0" />
              </BaseButton>
            ))}
          </Fragment>
        ) : null
      })}
    </Flex>
  )
}

export const RefinementsDisplay = observer(RefinementsDisplayContainer)
