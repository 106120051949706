import {history} from 'instantsearch.js/es/lib/routers/index.js'
import type { UiState } from 'instantsearch.js';
import he from 'he'

interface RouteState {
  query?: string
  page?: string
  brands?: string[]
  category?: string
  rating?: string
  price?: string
  free_shipping?: string
  sortBy?: string
  hitsPerPage?: string
};

interface AlgoliaRouting {
  router: typeof history
  stateMapping: {
    stateToRoute(uiState: UiState): any
    routeToState(routeState: RouteState): any
  }
}

const algoliaRouting = (
  serverUrl: string,
  searchIndex: string,
  algoliaIndexBase: string
): AlgoliaRouting => {
  const sortMapStateToUrl = {
    __products__bestsellers: 'Margin-best-sellers',
    __products__price_asc: 'price-low-to-high',
    __products__price_desc: 'price-high-to-low',
    __products__alpha_asc: 'product-name-a-to-z',
    __products__alpha_desc: 'product-name-z-to-a',
  } as const

  const sortMapUrlToState = {
    'Margin-best-sellers': '__products__bestsellers',
    'price-low-to-high': '__products__price_asc',
    'price-high-to-low': '__products__price_desc',
    'product-name-a-to-z': '__products__alpha_asc',
    'product-name-z-to-a': '__products__alpha_desc',
  } as const

  const filterOutAlgoliaQueries = (query: string) => {
    const algoliaQueries = ['q=', 'pmin=', 'pmax=', 'srule=', 'prefn1=', 'prefv1=']

    const queryName = query.split('=')[0]
    // If there is a start query set by algolia filter it out otherwise keep it
    if (queryName === 'page' ) {
      algoliaQueries.push('page=')
    }
    // filter out non matching queries
    const foundMatches = algoliaQueries.filter((algoliaQuery) => { 
     return query.indexOf(algoliaQuery) > -1
    })
    return foundMatches.length === 0
  }

  return {
    router: history({
      getLocation: () => // DO NOT REMOVE THIS - REQUIRED FOR SSR
      typeof window === 'undefined'
        ? (new URL(serverUrl) as unknown as (typeof window)['location'])
        : window.location,
      createURL({ qsModule, location, routeState }) {
        const { origin, pathname, hash, href, search} = location;
        // Get query string from current url and filter out Algolia related queries
        const urlQueryString = search ? search.substring(1).split('&').filter((param) => filterOutAlgoliaQueries(param)) : []
        // Remove undefined values from queryParams
        const keys = Object.keys(routeState).filter((key) => routeState[key] !== undefined)
        // Create query string
        const routeStateQuery = keys.length > 0 ? he.decode(qsModule.stringify(routeState)).split('&') : [];
         // Merged Algolia query string and url query string together
         const mergedQueries = new Set([...routeStateQuery, ...urlQueryString])
        // Convert query array into string and join into correct format
        let queryString = mergedQueries.size > 0 ? `?${Array.from(mergedQueries).join('&')}` : ''

        if (!queryString) {
          return `${origin}${pathname}${hash}`;
        }
      
        return `${origin}${pathname}${queryString}${hash}`;
      },
      //Synch application state to URL by parsing URL query parameters and convertring them into uiState
      parseURL({qsModule, location}) {
        const { search } = location;
        
        const queryParameters = qsModule.parse(search.slice(1));
        const {
          q = '',
          page = 1,
          srule,
          pmin,
          pmax,
        } = queryParameters;
      
        const pageNumber = Number(page) > 0 ? page : undefined

        return {
          query: decodeURIComponent(q as string),
          page: pageNumber,
          sortBy: srule
          ? sortMapUrlToState[srule as keyof typeof sortMapUrlToState]
          : undefined,
        // Populate the refinement list with filters (priceRange, refinements from prefn1 and prefv1)
        refinementList: {
          ...(pmin && pmax && {
            priceRange: [`£${pmin} - £${pmax}`],
          }),
          ...Object.keys(queryParameters)
            .filter((key) => key.startsWith('prefn') && queryParameters[`prefv${key.slice(5)}`])
            .reduce((acc: { [key: string]: string[] }, key) => {
              const numberForPref = key.match(/\d+/)?.[0];
              if (numberForPref) {
                acc[queryParameters[key] as string] = (queryParameters[`prefv${numberForPref}`] as string).split('|');
              }
              return acc;
            }, {})
        }
        };
      }
    }),
    stateMapping: {
      stateToRoute(uiState: UiState) {
        const indexUiState = uiState[searchIndex] || {}
        const pageNumber = Number(indexUiState.page) > 1 ? indexUiState.page : undefined
        const query = indexUiState.query || indexUiState.configure?.query
        const priceRange = indexUiState.refinementList?.priceRange?.[0]
        let rangeArray: any
        if (priceRange) {
          rangeArray = priceRange.match(/-?\d+(?:,\d{3})*(?:\.\d+)?/g)
        }

        const prefObject: {[key: string]: unknown} = {}
        const {refinementList} = indexUiState
        if (refinementList) {
          Object.entries(refinementList)
            .filter(([key]) => key !== 'priceRange')
            .forEach(([key, value], index) => {
              prefObject[`prefn${index + 1}`] = key
              prefObject[`prefv${index + 1}`] = Array.isArray(value) ? value.join('|') : value
            })
        }

        return {
          q: query,
          srule:
            sortMapStateToUrl[
              `${uiState[searchIndex]?.sortBy?.replace(
                algoliaIndexBase,
                ''
              )}` as keyof typeof sortMapStateToUrl
            ],
          pmin: priceRange && rangeArray[0],
          pmax: priceRange && rangeArray[1],
          page: pageNumber,
          ...prefObject,
        }
      },
      routeToState(routeState: RouteState) {
        const indexUiState = routeState[searchIndex] || {}
      const pageNumber = Number(routeState.page) > 1 ? routeState.page : undefined
      const query = routeState.query
        const prefObject: {[key: string]: unknown} = {}
        const {refinementList} = indexUiState
        if (refinementList) {
          Object.entries(refinementList)
          .filter(([key]) => key !== 'priceRange')
          .forEach(([key, value]) => {
            if (Array.isArray(value)) {
              prefObject[key] = value
            }
          })
        }

        //TODO: THIS SHOULD ALL BE COMING FROM THE INDEXUISTATE AND THE REASON WHY FILTERS AREN'T CONSISTENT AND WHY WE HAVE TO REAPPLY FILTERS ON PAGE LOAD ETC
        // SEE GITHUB FOR HOW IT SHOULD BE WORKING https://github.com/algolia/instantsearch/blob/master/examples/react/e-commerce/routing.ts
        return {
          [searchIndex]: {
            query,
            page: pageNumber,
            sortBy: routeState.srule
              ? `${algoliaIndexBase}${
                  sortMapUrlToState[routeState.srule as keyof typeof sortMapUrlToState]
                }`
              : null,
            refinementList: {
              ...(routeState.pmin && {
                priceRange: [`£${routeState.pmin} - £${routeState.pmax}`],
              }),
              ...prefObject,
            }
          },
        }
      },
    },
  }
}


export default algoliaRouting
