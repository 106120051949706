import React, {useEffect, useRef} from 'react'
import {useClearRefinements} from 'react-instantsearch-hooks-web'
import {Box, Flex, Text} from '../../../../vanilla'
import {REFINEMENTS_MENU} from '../../../../utils/constants'
import {clearAllItem} from '../styles.css'
import {RefinementsMenuToggler} from './ui/refinements-menu-toggler'
import {DynamicRefinements} from './dynamic-refinements'
import {useLocation} from 'react-router-dom'
import {useClipDirectionAnimation} from '../../../../hooks/use-clip-direction-animation'

interface DesktopRefinementsProps {
  category?: {name: string; id: string}
  refinements: {label: string; attribute: string}[]
}

const ANIMATION_DURATION_MS = 250

export const DesktopRefinements = (props: DesktopRefinementsProps) => {
  const {refinements, category} = props
  const {canRefine, refine} = useClearRefinements()
  const {pathname} = useLocation()

  const contentRef = useRef<HTMLUListElement | null>(null)

  const {toggleAnimation, animationCSSProperties, animationState} = useClipDirectionAnimation(
    contentRef,
    'top',
    {
      duration: ANIMATION_DURATION_MS,
      showStateDirectionValue: 35,
    },
    true
  )

  useEffect(() => {
    if (animationState === 'show') {
      toggleAnimation()
    }
  }, [pathname])

  return (
    <Box position="relative" display={['none', 'block']} style={{width: 135}}>
      <RefinementsMenuToggler onClick={toggleAnimation} />
      <Box
        ref={contentRef}
        as="ul"
        background="white"
        position="absolute"
        flexDirection="column"
        display={animationState === null ? 'none' : 'block'}
        style={{
          width: REFINEMENTS_MENU.WIDTH,
          zIndex: 120,
          ...animationCSSProperties,
        }}
      >
        {canRefine && (
          <Box as="li" cursor="pointer" border="1px" borderColor="gray200" borderBottom="0px">
            <Flex
              align="center"
              justifyContent="center"
              padding="16px"
              position="relative"
              onClick={() => refine()}
            >
              <Text variant="text3" lineHeight="short" color="accent3" className={clearAllItem}>
                Clear All
              </Text>
            </Flex>
          </Box>
        )}
        {refinements.map((refinement, idx) => (
          <DynamicRefinements
            key={refinement.attribute}
            isInDrawer={false}
            category={category}
            lastItem={idx === refinements.length - 1}
            {...refinement}
          />
        ))}
      </Box>
    </Box>
  )
}
