import React, {useEffect, useRef, useState} from 'react'
import ReactDOM from 'react-dom'
import {Box, Button, Stack, Text, Flex} from '../../../../vanilla'
import {TRANSITION_DURATION} from '../../../header/mobile-navigation-drawer'
import {CloseIcon} from '../../../icons'
import {DynamicRefinements} from './dynamic-refinements'
import {ObjectValues} from '../../../../types/utils'
import {RefinementsMenuToggler} from './ui/refinements-menu-toggler'

import useLockBodyScrolling from '../../../../hooks/use-lock-body-scrolling'
import {
  filtersMenuContainer,
  refinementsDrawerSlideLeft,
  refinementsDrawerSlideRight,
} from '../styles.css'
import {isServer} from '../../../../utils/utils'
import {useClearRefinements} from 'react-instantsearch-hooks-web'
import {clearAllItem} from '../styles.css'

interface MobileRefinementsDrawerProps {
  refinements: {label: string; attribute: string}[]
  category?: {name: string; id: string}
  animation: FilterAnimationType | null
  onClose: () => void
}

const MobileRefinementsDrawer = (props: MobileRefinementsDrawerProps) => {
  const {refinements, category, animation, onClose} = props
  const {canRefine, refine} = useClearRefinements();

  const contentRef = useRef<HTMLDivElement | null>(null)

  useLockBodyScrolling(animation === filterAnimationMap.SHOW)

  useEffect(() => {
    const onClick = ({target}: any) => {
      !contentRef?.current?.contains(target) && onClose?.()
    }

    if (contentRef?.current) {
      document.addEventListener('click', onClick, {capture: true})
    }

    return () => document.removeEventListener('click', onClick, {capture: true})
  }, [])

  const container = isServer ? null : document.getElementsByClassName('react-target')[0]

  return animation && container
    ? ReactDOM.createPortal(
        <Stack
          className={filtersMenuContainer}
          top="0px"
          left="0px"
          zIndex="overlay"
          position="fixed"
          height="fullvh"
          width="full"
          style={{
            animationName: animation ? desktopFiltersAnimationsMap[animation] : undefined,
            transition: `background-color ${TRANSITION_DURATION}ms ease-out`,
            backgroundColor:
              animation === filterAnimationMap.SHOW ? 'rgba(0,0,0,0.4)' : 'rgba(0,0,0,0)',
          }}
        >
          <Box
            ref={contentRef}
            aria-label="Mobile refinements drawer"
            position="absolute"
            top="0px"
            left="0px"
            bottom="0px"
            zIndex="docked"
            backgroundColor="white"
            padding="0px"
            margin="0px"
            style={{
              width: 'calc(100% - 44px)',
              boxShadow: '2px 0 10px 0 rgb(0 0 0 / 20%)',
            }}
          >
            <Stack position="relative" gap="0px" height="full">
              <Button
                variant="unstyled"
                color="inherit"
                position="absolute"
                height="auto"
                paddingX="0px"
                style={{right: -30, top: 4}}
                onClick={onClose}
                iconLeft={<CloseIcon color="white" boxSize="28px" />}
              />
              <Stack spacing="0px">
                <Box paddingY="16px" backgroundColor="gray50">
                  <Text as="h3" variant="heading2" lineHeight="tall" align="center">
                    Filters
                  </Text>
                </Box>
                <Box as="ul">
                  {canRefine && (
                    <Box as="li" cursor="pointer" border="1px" borderColor="gray200" borderBottom="0px">
                      <Flex
                        align="center"
                        justifyContent="center"
                        padding="16px"
                        position="relative"
                        onClick={() => refine()}
                      >
                        <Text variant="text3" lineHeight="short" color="accent3" className={clearAllItem}>
                          Clear All
                        </Text>
                      </Flex>
                    </Box>
                  )}
                  {refinements.map((refinement, idx) => (
                    <DynamicRefinements
                      isInDrawer={true}
                      category={category}
                      key={refinement.attribute}
                      lastItem={idx === refinements.length - 1}
                      {...refinement}
                    />
                  ))}
                </Box>
              </Stack>
            </Stack>
          </Box>
        </Stack>,
        container
      )
    : null
}

interface MobileRefinementsProps {
  refinements: {label: string; attribute: string}[]
  category?: {name: string; id: string}
}

const filterAnimationMap = {
  SHOW: 'show',
  HIDE: 'hide',
} as const

type FilterAnimationType = ObjectValues<typeof filterAnimationMap>

const desktopFiltersAnimationsMap: Record<FilterAnimationType, string> = {
  [filterAnimationMap.SHOW]: refinementsDrawerSlideLeft,
  [filterAnimationMap.HIDE]: refinementsDrawerSlideRight,
} as const

export const MobileRefinements = (props: MobileRefinementsProps) => {
  const {refinements, category} = props

  const [animation, setAnimation] = useState<FilterAnimationType | null>(null)

  const onTogglerClickHandler = () => {
    setAnimation((prev) => {
      if (prev === null) {
        return filterAnimationMap.SHOW
      }
      return filterAnimationMap.SHOW === prev ? filterAnimationMap.HIDE : filterAnimationMap.SHOW
    })
  }

  const onClose = () => {
    setAnimation(filterAnimationMap.HIDE)
  }

  return (
    <Box display={['block', 'none']} width="half">
      <RefinementsMenuToggler onClick={onTogglerClickHandler} />
      <MobileRefinementsDrawer
        category={category}
        refinements={refinements}
        animation={animation}
        onClose={onClose}
      />
    </Box>
  )
}
